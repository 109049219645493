import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import WarehousingTemplate from '../templates/warehousing-template';

const Warehousing = ({ data, location }) => {
	const content = data.pagesJson;
	const path = location.pathname;

	return (
		<Layout>
			<SEO
				title="Warehousing & Distribution"
				description={content.description}
			/>
			<WarehousingTemplate copy={content} path={path} />
		</Layout>
	);
};

export default Warehousing;

export const pageQuery = graphql`
	query WarehousingPageQuery {
		pagesJson(page: { eq: "warehousing" }) {
			description
			masthead {
				title
				img
			}
			...servicesCopy
			...services
			form {
				title
				phone {
					format
					raw
				}
				img
			}
		}
	}
`;
